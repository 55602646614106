import { post } from "./apiService";

export const sendSingleEmail = async (payload) => {
  return post({ path: 'Email/sendSingleEmail', body: payload });
}

export const emailEmployerRemittanceReport = async (payload) => {
  return post({ path: 'Report/sendEmployerRemittanceReport', body: payload });
}

export const emailPD7AReport = async (payload) => {
  return post({ path: 'Report/sendPD7AReport', body: payload });
}

export const emailPaystubReport = async (payload) => {
  return post({ path: 'Report/sendPaystubReport', body: payload });
}

export const emailPaystubReportToAll = async (payload) => {
  return post({ path: 'Report/sendPaystubReportToAll', body: payload });
}

export const emailGroupedPaystubs = async (payload) => {
  return post({ path: 'Report/sendGroupedPaystubs', body: payload });
}

export const emailPayrollReport = async (payload) => {
  return post({ path: 'Report/sendPayrollReport', body: payload });
}

export const emailQuoteItemsReport = async (payload) => {
  return post({ path: 'Report/sendQuoteItemsReport', body: payload });
}

export const emailInvoiceItemsReport = async (payload) => {
  return post({ path: 'Report/sendInvoiceItemsReport', body: payload });
}

export const createEmployerRemittanceReportFile = async (payload) => {
  return post({ path: 'Report/createEmployerRemittanceReportFile', body: payload });
}

export const createPD7AReportFile = async (payload) => {
    return post({ path: 'Report/createPD7AReportFile', body: payload });
}

export const createEmployeePaystubReportFile = async (payload) => {
  return post({ path: 'Report/createEmployeePaystubReportFile', body: payload });
}

export const createT4ReportFile = async (payload) => {
    return post({ path: 'Report/createT4ReportFile', body: payload });
}

export const createPaystubReportFile = async (payload) => {
  return post({ path: 'Report/createPaystubReportFile', body: payload });
}

export const createPayrollReportFile = async (payload) => {
  return post({ path: 'Report/createPayrollReportFile', body: payload });
}

export const createQuotesReportFile = async (payload) => {
  return post({ path: 'Report/createQuotesReportFile', body: payload });
}

export const createQuoteItemsReportFile = async (payload) => {
  return post({ path: 'Report/createQuoteItemsReportFile', body: payload });
}

export const createInvoicesReportFile = async (payload) => {
  return post({ path: 'Report/createInvoicesReportFile', body: payload });
}

export const createInvoiceItemsReportFile = async (payload) => {
  return post({ path: 'Report/createInvoiceItemsReportFile', body: payload });
}

export const createIncomeReportFile = async (payload) => {
  return post({ path: 'Report/createIncomeReportFile', body: payload });
}

export const createExpensesReportFile = async (payload) => {
  return post({ path: 'Report/createExpensesReportFile', body: payload });
}

export const createAccountsReceivableReportFile = async (payload) => {
  return post({ path: 'Report/createAccountsReceivableReportFile', body: payload });
}

export const createAccountsPayableReportFile = async (payload) => {
  return post({ path: 'Report/createAccountsPayableReportFile', body: payload });
}